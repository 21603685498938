import React, {useState, useEffect, useMemo} from 'react'
import {graphql} from 'gatsby';

import Stock from '../types/Stock';
import IndexSearchResult from '../components/IndexSearchResult';
import {IndexPageContext} from '../contexts';

// Since this page is not using the Layout component then we
// need to import the css
import 'antd/dist/antd.css';
import '../styles/global.css';
import '../styles/tailwind.css';
import '../styles/overrides.css';

export const query = graphql`
    query IndexPageQuery {
      stocks: allPseStocks (filter: { status: { eq: "OPEN" } }) {
        nodes {
          company_name
          ticker_symbol
          status
        }
      }
      site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          siteUrl
          defaultImage: image
          titleTemplate
          defaultKeywords: keywords
        }
      }
    }
`;

const IndexPage = ({data}) => {
    let stocks: Stock[] = useMemo(function () {
        return data.stocks.nodes.filter((s) => s.ticker_symbol && s.status === 'OPEN');
    }, [data]);

    const [searchTerm, setSearchTerm] = useState('');

    function handleSearch(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            setSearchTerm(e.target.value);
        }
    }

    return (
        <IndexPageContext.Provider value={{stocks}}>
            <div className="bg-purple-200 min-h-screen px-4 py-16 flex justify-center lg:px-8">
                <div className="flex flex-col w-full md:w-2/3 lg:2/5 pt-4">
                    <div>
                        <h1 className="font-extrabold text-purple-600 tracking-tight text-6xl font-alloy-ink text-center">Oh
                            My
                            Stonks!</h1>
                    </div>
                    <div className="">
                        <form className="mt-8 sm:flex">
                            <label htmlFor="email-address" className="sr-only">Search a stonk...</label>
                            <input type="text"
                                   autoFocus={true}
                                   className="w-full px-5 py-3 shadow-md placeholder-gray-300 focus:ring-purple-500 focus:border-purple-500 border-none rounded-md"
                                   placeholder="Search a stonk..." onKeyPress={handleSearch}/>
                        </form>
                    </div>
                    <div className="pt-8">
                        <IndexSearchResult searchTerm={searchTerm}/>
                    </div>
                </div>
            </div>
        </IndexPageContext.Provider>
    );
}

export default IndexPage
