export const eodEndpoint = '/api/eod';

export const LOADING_STATES = {
    LOADING: 'loading',
    IDLE: 'idle'
};

export const ROUTES = {
    DOWNLOAD: '/',
    STOCK_LOCKUP: '/0/stock-lookup',
    CHART: '/0/chart',
    MOST_ACTIVE: '/0/most-active',
    SCREENER: '/0/screener',
    API: 'https://www.notion.so/PSE-Lookup-API-a68e29bbb9814635a92cef8519d1e0df',
    ABOUT: '/0/about',
    STOCK: '/0/stock',
    STOCKS: '/0/stocks',
    DOWNLOAD_DATABASE_DUMP: '/0/download-database-dump'
};

export function generateRoute(route, params) {
    if (route === ROUTES.STOCK) {
        return `${route}/${params.stock_code}`;
    }

    if (route === ROUTES.CHART) {
        return `${route}/?view=${params.stock_code}`;
    }

    if (route === ROUTES.STOCKS) {
        return `${route}/${params.stock_code}`;
    }

    return route;
}

export function getStringSorter(sortKey) {
    return function (a, b) {
        const nameA = a[sortKey].toUpperCase();
        const nameB = b[sortKey].toUpperCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    }
}

export function getSorter(sortKey) {
    return (a, b) => {
        const nameA = parseInt(a[sortKey]);
        const nameB = parseInt(b[sortKey]);

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    }
}

export const lightweightChartColors = {
    default: {
        topColor: 'rgba(33, 150, 243, 0.56)',
        bottomColor: 'rgba(33, 150, 243, 0.04)',
        lineColor: 'rgba(33, 150, 243, 1)',
    },
    down: {
        topColor: 'rgba(255, 82, 82, 0.56)',
        bottomColor: 'rgba(255, 82, 82, 0.04)',
        lineColor: 'rgba(255, 82, 82, 1)',
    },
    up: {
        topColor: 'rgba(0, 150, 136, 0.56)',
        bottomColor: 'rgba(0, 150, 136, 0.04)',
        lineColor: 'rgba(0, 150, 136, 1)',
    }
}