import React, {useContext, useEffect, useState} from 'react';
import MiniStockView from './mini-stock-view';
import Stock from '../types/Stock';
import Fuse from 'fuse.js';
import {IndexPageContext} from '../contexts';

interface ResultItemData {
    stock: Stock,
    isActive: Boolean
}

function ResultItem({data}: { data: ResultItemData }) {
    const stock = data.stock;

    return (
        <div className="flex flex-col bg-white shadow overflow-hidden rounded-md animate-fade-in-up">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-xl leading-6 font-medium text-gray-900">{stock.ticker_symbol}</h3>
                <p className="max-w-2xl text-sm text-gray-500">{stock.company_name}</p>
            </div>
            {data.isActive && <MiniStockView pageContext={stock}/>}
        </div>
    );
}


function IndexSearchResult({searchTerm}) {
    const {stocks} = useContext(IndexPageContext);
    const [activeStock, setActiveStock] = useState<Stock>(null);
    const [resultItems, setResultItems] = useState<Stock[]>([]);

    useEffect(function doSearch() {
        const fuse = new Fuse(stocks, {
            keys: [{
                name: 'ticker_symbol',
                weight: 2
            }, 'company_name']
        });

        const searchResult = fuse.search<Stock>(searchTerm);
        const tickerSymbolMatch = searchResult.length && searchResult[0].item.ticker_symbol === searchTerm.toUpperCase();
        if (tickerSymbolMatch) {
            // display this stock immediately since we have an exact match on the ticker symbol
            let item = searchResult[0].item;
            setResultItems([item]);
            setActiveStock(item);
            return;
        }

        const searchResults = searchResult.map((result) => result.item);
        setActiveStock(null);
        setResultItems(searchResults);
    }, [searchTerm]);

    function handleResultItemClicked(stock) {
        setActiveStock(stock);
    }

    function isActiveStock(compareStock) {
        if (!activeStock)
            return false;

        return activeStock.ticker_symbol === compareStock.ticker_symbol;
    }

    return (
        <div>
            <ul role="list" className="space-y-3">
                {resultItems.map((item) => {
                    const data = {
                        stock: item,
                        isActive: isActiveStock(item)
                    };

                    return (
                        <li key={item.ticker_symbol} onClick={() => handleResultItemClicked(item)}>
                            <ResultItem data={data}/>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default IndexSearchResult;